<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-bell-o"></i><strong> Alertes</strong>
              <div class="card-header-actions">
                <b-button variant="success" @click="onExport" title="Exporter en PDF" class="mr-2" v-b-tooltip.hover>
                  <i class="fa fa-upload"></i>
                </b-button>

                <b-button variant="primary" @click="newAlertModal = true">
                  <i class="fa fa-plus"></i>
                  Nouvelle alerte
                </b-button>

                <b-modal :title="modalTitle" class="modal-primary" v-model="newAlertModal"
                         @hide="onModalClose">
                  <b-form>
                    <p class="text-danger" v-if="hasError">{{errorMessage}}</p>

                    <c-input container-class="mb-3 mt-3" type="select" label="Evènement" v-model="event"
                             :options="eventList" :state="eventState">
                      <template slot="first">
                        <option :value="null">-- Sélectionnez un évènement --</option>
                      </template>
                      Veuillez sélectionner un évènement
                    </c-input>

                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-clock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="number" class="form-control" placeholder="Délai" :min=1 :step=1
                                    v-model="remindInterval" :state="remindIntervalState"
                                    aria-describedby="remind-feedback"/>
                      <b-form-select v-model="remindIntervalUnit" class="form-control">
                        <option value="HOUR" selected>Heure(s)</option>
                        <option value="DAY" selected>Jour(s)</option>
                        <option value="WEEK" selected>Semaine(s)</option>
                        <option value="MONTH" selected>Mois</option>
                        <option value="YEAR" selected>An(s)</option>
                      </b-form-select>
                      <b-form-select v-model="moment" class="form-control">
                        <option value="BEFORE" selected>Avant</option>
                        <option value="AFTER" selected>Après</option>
                      </b-form-select>
                      <b-form-invalid-feedback id="remind-feedback">
                        Veuillez entrer un délai
                      </b-form-invalid-feedback>
                    </b-input-group>

                    <c-input container-class="mb-3" type="textarea" label="Commentaire"
                             placeholder="Entrez un commentaire..." v-model="comment">
                    </c-input>

                    <b-input-group class="mb-3 text-center">
                      <b-form-checkbox class="text-muted cursor-pointer mx-auto" v-model="active" switch>
                        {{activeText}}
                      </b-form-checkbox>
                    </b-input-group>
                  </b-form>

                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmit"
                                    :fetching="fetchingNewAlert">
                      {{submitBtnText}}
                    </button-spinner>
                    <b-button variant="secondary" @click="newAlertModal = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
              </div>
            </div>
            <div class="text-center" v-if="fetchingAlertList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-row v-else-if="alerts.length" class="exploitations">
              <c-table :table-data="alerts" :fields="fields" :actions="actions" :per-page=5 striped outlined
                       fixed></c-table>
            </b-row>
            <p class="text-center" v-else>Aucune alerte personnalisée dans cette exploitation.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Regex, Toast, asset, IO} from "../../../helpers"

  export default {
    name: "ExploitationAlerts",
    title: "PIA - Alertes",
    data() {
      return {
        alerts: [],

        fields: [
          {key: 'alertEvent.name', label: 'Evènement', sortable: true},
          {key: 'remind', label: 'Rappel'},
          {key: 'momentText', label: 'Moment', sortable: true},
          {key: 'status', label: 'Statut', sortable: true},
          {key: 'actions', label: 'Actions'},
        ],
        actions: [
          {type: 'primary', icon: 'icon-note', title: 'Modifier', handler: this.editAlert},
          {type: 'danger', icon: 'icon-trash', title: 'Supprimer', handler: this.deleteAlert},
        ],

        newAlertModal: false,
        event: null,
        remindInterval: '',
        remindIntervalUnit: 'DAY',
        moment: 'BEFORE',
        comment: '',
        active: true,

        submitted: false,
        error: null,

        eventList: [],
        fetchingEventList: false,

        fetchingAlertList: false,
        fetchingNewAlert: false,
        selectedAlert: null
      }
    },
    created() {
      this.fetchingAlertList = true
      Api.get('/exploitation/alert/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.alerts = res.data.data.map(alert => ({
              ...alert,
              remind: alert.remindInterval + ' ' + this.intervalUnitConverter[alert.remindIntervalUnit],
              status: alert.active ? 'Activée' : 'Désactivée',
              statusVariant: alert.active ? 'success' : 'warning',
              momentText: this.momentConverter[alert.moment],
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingAlertList = false
        })

      this.fetchingEventList = true
      Api.get('/exploitation/event/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.eventList = res.data.data.map(event => ({
              value: event.id,
              text: event.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingEventList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      modalTitle() {
        return this.selectedAlert ? 'Modifier une alerte' : 'Nouvelle alerte'
      },
      submitBtnText() {
        return this.selectedAlert ? 'Enregistrer' : 'Créer'
      },
      momentConverter() {
        return {
          BEFORE: 'Avant',
          AFTER: 'Après'
        }
      },
      intervalUnitConverter() {
        return {
          HOUR: 'Heure(s)',
          DAY: 'Jour(s)',
          WEEK: 'Semaine(s)',
          MONTH: 'Mois',
          YEAR: 'An(s)'
        }
      },
      activeText() {
        return this.active ? 'Activée' : 'Désactivée'
      },
      eventState() {
        return !this.submitted || Regex.isPositiveNumber(this.event) ? null : false
      },
      remindIntervalState() {
        return !this.submitted || Regex.isPositiveNumber(this.remindInterval) ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    methods: {
      asset: asset,
      valid() {
        if(!Regex.isPositiveNumber(this.event) || !Regex.isPositiveNumber(this.remindInterval))
          return false

        if(this.alerts.find(item => this.event === item.alertEvent.id
                                      && this.remindInterval === str(item.remindInterval)
                                      && this.remindIntervalUnit === item.remindIntervalUnit
                                      && this.moment === item.moment)
        ) {
          this.error = { message: 'Une alerte comportant les même paramètres existe déjà !' }
          return false
        }

        return true
      },
      onModalClose() {
        this.selectedAlert = null
        this.event = null
        this.remindInterval = ''
        this.remindIntervalUnit = 'DAY'
        this.moment = 'BEFORE'
        this.comment = ''
        this.active = true
        this.submitted = false
        this.error = null
      },
      newAlert() {
        this.submitted = true
        if (!this.valid()) return

        this.fetchingNewAlert = true
        Api.post('/exploitation/alert/create', {
          exploitationId: this.exploitationId,
          alertEventId: this.event,
          remindInterval: this.remindInterval,
          remindIntervalUnit: this.remindIntervalUnit,
          moment: this.moment,
          comment: this.comment,
          active: this.active,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newAlertModal = false
              const alert = res.data.data
              this.alerts.push({
                ...alert,
                remind: alert.remindInterval + ' ' + this.intervalUnitConverter[alert.remindIntervalUnit],
                status: alert.active ? 'Activée' : 'Désactivée',
                statusVariant: alert.active ? 'success' : 'warning',
                momentText: this.momentConverter[alert.moment],
              })
              Toast.success('Alerte créée avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewAlert = false
          })
      },
      updateAlert() {
        this.submitted = true
        if (!this.valid()) return

        this.fetchingNewAlert = true
        Api.post('/exploitation/alert/update', {
          exploitationId: this.exploitationId,
          alertId: this.selectedAlert.id,
          alertEventId: this.event,
          remindInterval: this.remindInterval,
          remindIntervalUnit: this.remindIntervalUnit,
          moment: this.moment,
          comment: this.comment,
          active: this.active,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newAlertModal = false
              const alert = res.data.data
              this.alerts = this.alerts.filter((item, index, array) => item.id !== this.selectedAlert.id)
              this.alerts.push({
                ...alert,
                remind: alert.remindInterval + ' ' + this.intervalUnitConverter[alert.remindIntervalUnit],
                status: alert.active ? 'Activée' : 'Désactivée',
                statusVariant: alert.active ? 'success' : 'warning',
                momentText: this.momentConverter[alert.moment],
              })
              Toast.success('Alerte mise à jour avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewAlert = false
          })
      },
      onSubmit() {
        this.selectedAlert ? this.updateAlert() : this.newAlert()
      },
      editAlert(alert) {
        this.selectedAlert = alert

        this.event = alert.alertEvent.id
        this.remindInterval = alert.remindInterval
        this.remindIntervalUnit = alert.remindIntervalUnit
        this.moment = alert.moment
        this.comment = alert.comment
        this.active = alert.active

        this.newAlertModal = true
      },
      deleteAlert(alert) {
        const res = confirm(`Etes-vous sûr de vouloir cette alerte ?`)
        if (res) {
          // this.fetchingDeleteAlert = true
          Api.post('/exploitation/alert/delete', {
            exploitationId: this.exploitationId,
            alertId: alert.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.alerts = this.alerts.filter((item, index, array) => item.id !== alert.id)
                Toast.success('Alerte supprimée avec succès !')
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              // this.fetchingDeleteAlert = false
            })
        }
      },
      onExport() {
        IO.exportDataTable(this.alerts, 'alerts', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste des alertes personnalisées',
          headers: [
            {title: 'Evènement', key: 'alertEvent.name', width: '*'},
            {title: 'Rappel', key: 'remind', width: '*'},
            {title: 'Moment', key: 'momentText', width: 'auto'},
            {title: 'Statut', key: 'status', width: 'auto'},
          ]
        })
      }
    }
  }
</script>

<style scoped>

</style>
